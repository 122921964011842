import React from "react";

import Logo from "./logo.png";
import Time from "./Time";

const Header = () => {
  return (
    <header>
      <img src={Logo} alt="dc9111 logo" id="top" className="logo" />
      <div className="date-time-container">
        <p className="date">{new Date().toDateString()}</p>
        <Time />
      </div>
    </header>
  );
};

export default Header;
