import React, { useContext, useState } from "react";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [activeWindows, setActiveWindows] = useState([]);

  return (
    <AppContext.Provider
      value={{ activeWindows, setActiveWindows: setActiveWindows }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useGlobalContext };
