import React from "react";
import { RxCross2 } from "react-icons/rx";
import { useGlobalContext } from "./context";
import "./window.css";

import w from "./windows";

const Window = ({ wid }) => {
  const win = w.filter((e) => e.id === wid)[0];
  const { activeWindows, setActiveWindows } = useGlobalContext();

  const handleClose = () => {
    setActiveWindows(activeWindows.filter((e) => e !== wid));
  };
  return (
    <div className="window">
      <div
        className={`window-title-bar ${
          window.innerWidth > 598 ? "drag-handle" : ""
        }`}
      >
        <p className="title">
          <span>{"> "}</span> {win.title}
        </p>
        <button className="close-window" onClick={handleClose}>
          <RxCross2 />
        </button>
      </div>
      <div className="window-content">{win.internalStructure}</div>
    </div>
  );
};

export default Window;
