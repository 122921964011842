import React, { useState, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa6";
import { VscLinkExternal } from "react-icons/vsc";
import { updates } from "./data";

export const Sidebar = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const targetDate = new Date("2024-10-19");
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  return (
    <aside className={isSideBarOpen ? "open" : "close"}>
      <button
        className="sidebar-toggle"
        onClick={() => setIsSideBarOpen(!isSideBarOpen)}
      >
        <FaChevronRight />
      </button>
      <h2 className="sidebar-title">Media Updates</h2>
      <div className="updates-container">
        {updates.map((el) => (
          <article className="media-update" key={el.id}>
            <a href={el.url} className="post-link">
              {el.icon}{" "}
              <span>
                {el.title}{" "}
                <sup>
                  <VscLinkExternal />
                </sup>{" "}
              </span>
            </a>
            <p className="post-date">{el.date}</p>
          </article>
        ))}
      </div>
      <p className="days-left" style={{ fontSize: '1em' }}>
        {timeLeft.days !== undefined
          ? `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`
          : "See you all next year!"}
      </p>
    </aside>
  );
};
