import React from "react";
import { useGlobalContext } from "./context";

const Icon = ({ props }) => {
  const { activeWindows, setActiveWindows } = useGlobalContext();
  const handleWindowOpen = (wid) => {
    if (!activeWindows.includes(wid)) {
      setActiveWindows([...activeWindows, wid]);
    }
  };
  return (
    <div
      className="desktop-icon-wrapper"
      onDoubleClick={() => {
        if (window.innerWidth > 598) {
          handleWindowOpen(props.id);
        }
      }}
      onClick={() => {
        if (window.innerWidth <= 598) {
          handleWindowOpen(props.id);
        }
      }}
    >
      {props.icon}
      <p className="desktop-icon-filename">{props.title}</p>
    </div>
  );
};

export default Icon;
