import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const Time = () => {
  const [time, setTime] = useState("");
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().toTimeString().split(" ")[0]} `);
    }, 1000);
  }, [time]);
  return <p className="time">{time}</p>;
};

export default Time;
