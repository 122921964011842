import React from "react";
import Header from "./Header";
import { Sidebar } from "./Sidebar";

import { useGlobalContext } from "./context";

import Window from "./Window";
import w from "./windows";

import Draggable from "react-draggable";
import Icon from "./Icon";

const App = () => {
  const { activeWindows} = useGlobalContext();

  const handleWindowActive = (i) => {
    const el = document.getElementsByClassName("active-window");
    if (el.length > 0) {
      el[0].classList.remove("active-window");
    }
    document.getElementById(i).classList.add("active-window");
  };

  return (
    <div className="app-wrapper">
      <Header />
      <div className="content">
        <Sidebar />
        <main>
          {activeWindows.map((wid, index) => {
            return (
              <Draggable key={wid} bounds="parent" handle=".drag-handle">
                <div
                  className="window-wrapper"
                  id={wid}
                  onClick={() => handleWindowActive(wid)}
                  style={
                    window.innerWidth > 598
                      ? {
                          top: index * 20 + 5,
                          right: index * 20 + 5,
                        }
                      : { top: 0, left: 0 }
                  }
                >
                  <Window wid={wid} />
                </div>
              </Draggable>
            );
          })}
          <div className="icon-tray">
            {w.map((i) => {
              return <Icon props={i} key={i.id} />;
            })}
          </div>
        </main>
      </div>
    </div>
  );
};

export default App;
