import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaGithub,
  FaEnvelope,
  FaTwitch,
  FaFacebookMessenger,
  FaDiscord,
  FaLink,
} from "react-icons/fa";

import { FaXTwitter } from "react-icons/fa6";

import { SiBuymeacoffee, SiMedium } from "react-icons/si";

const links = [
  {
    Name: "Facebook",
    URL: "https://www.facebook.com/dc9111",
    Color: "#3b5998",
    icon: <FaFacebookF />,
    Text_Color: "#fff",
  },

  {
    Name: "Instagram",
    URL: "https://www.instagram.com/dc_9111",
    Color: "#DD2A7B",
    icon: <FaInstagram />,
    Text_Color: "#fff",
  },

  {
    Name: "Twitter",
    URL: "https://x.com/dc_9111",
    Color: "#1DA1F2",
    icon: <FaXTwitter />,
    Text_Color: "#fff",
  },

  {
    Name: "LinkedIn",
    URL: "https://www.linkedin.com/company/dc-9111",
    Color: "#2867B2",
    icon: <FaLinkedinIn />,
    Text_Color: "#fff",
  },

  {
    Name: "YouTube",
    URL: "https://www.youtube.com/channel/UCpHngVxoYmSBG5Yp2An3bTQ",
    Color: "#ff0000",
    icon: <FaYoutube />,
    Text_Color: "#fff",
  },

  {
    Name: "GitHub",
    URL: "http://github.com/dc-9111",
    Color: "#6e5494",
    icon: <FaGithub />,
    Text_Color: "#fff",
  },

  {
    Name: "Email",
    URL: "mailto:mail@defcon9111.org",
    icon: <FaEnvelope />,
    Color: "#e17055",
    Text_Color: "#ffffff",
  },

  {
    Name: "Twitch",
    URL: "https://twitch.tv/dc_9111",
    icon: <FaTwitch />,
    Color: "#9147fe",
    Text_Color: "#ffffff",
  },

  {
    Name: "Messenger",
    URL: "https://m.me/dc_9111",
    Color: "#006AFF",
    icon: <FaFacebookMessenger />,
    Text_Color: "#fff",
  },

  {
    Name: "Discord",
    URL: "https://discord.gg/tchsmJ8ZZQ",
    icon: <FaDiscord />,
    Color: "#5865F2",
    Text_Color: "#ffffff",
  },

  {
    Name: "Donate",
    URL: "https://www.buymeacoffee.com/defcon9111",
    icon: <SiBuymeacoffee />,
    Color: "#40dca5",
    Text_Color: "#ffffff",
  },

  {
    Name: "Medium",
    URL: "https://medium.com/@dcg9111",
    icon: <SiMedium />,
    Color: "#00ab6c",
    Text_Color: "#ffffff",
  },

  {
    Name: "DC9111",
    URL: "https://defcon9111.org/",
    icon: <FaLink />,
    Color: "#ffb142",
    Text_Color: "#ffffff",
  },
];

const updates = [
    {
    id: "u07",
    icon: <FaXTwitter className="sidebar-icon" />,
    title: "0x07 Ends!🔥",
    url: "#",
    date: "Oct 24, 2024",
  },
  // {
  //   id: "u4",
  //   icon: <FaXTwitter className="sidebar-icon" />,
  //   title: "0x07 Call For Papers out now!🔥",
  //   url: "https://x.com/dc_9111/status/1825921150154846245",
  //   date: "Aug 20, 2024",
  // },
  // {
  //   id: "u3",
  //   icon: <FaLinkedinIn className="sidebar-icon" />,
  //   title: "0x07 Goons!!🔥",
  //   url: "https://www.linkedin.com/feed/update/urn:li:activity:7225191135239921664",
  //   date: "Aug 2, 2024",
  // },
  // {
  //   id: "u2",
  //   icon: <FaXTwitter className="sidebar-icon" />,
  //   title: "0x07 Dates out now!🔥",
  //   url: "https://x.com/dc_9111/status/1816486258337624497",
  //   date: "Jul 25, 2024",
  // },
  // {
  //   id: "u1",
  //   icon: <FaXTwitter className="sidebar-icon" />,
  //   title: "How about 0x07!🔥",
  //   url: "https://x.com/dc_9111/status/1783382449629827482",
  //   date: "Apr 25, 2024",
  // },
];

const team = [
  {
    id: 0,
    name: "Prasenjit Gautam",
    url: "https://linkedin.com/in/dotslashtx",
    imgURL: require("./assets/images/TX2.jpeg"),
  },
  {
    id: 1,
    name: "Ritesh Gupta",
    url: "https://linkedin.com/in/r1t35h",
    imgURL: require("./assets/images/rg.jpg"),
  },
  {
    id: 2,
    name: "Anushka Soni",
    url: "https://linkedin.com/in/anushka-soni-0xb/",
    imgURL: require("./assets/images/AS.jpg"),
  },
  {
    id: 3,
    name: "Sameep Shukla",
    url: "https://linkedin.com/in/sameepshukla/",
    imgURL: require("./assets/images/sash.jpg"),
  },  
  {
    id: 4,
    name: "Aditya Nama",
    url: "https://linkedin.com/in/adiintify/",
    imgURL: require("./assets/images/AN.png"),
  },
  {
    id: 5,
    name: "Mayank Malik",
    url: "https://linkedin.com/in/mostwanted002/",
    imgURL: require("./assets/images/MM.jpg"),
  },
  {
    id: 6,
    name: "Eshan Singh",
    url: "https://linkedin.com/in/eshan-singh04/",
    imgURL: require("./assets/images/ES.jpg"),
  },
  {
    id: 7,
    name: "Ashwin Kumar",
    url: "https://linkedin.com/in/ashwinksingh",
    imgURL: require("./assets/images/AKS.jpg"),
  },
  // Goons Side
  {
    id: 8,
    name: "Abhinav",
    url: "https://linkedin.com/in/abhinav-yadav-121025230/",
    imgURL: require("./assets/images/Abhinav.jpg"),
  },
  //   {
  //   id: 8,
  //   name: "Achala Singh",
  //   url: "https://linkedin.com/in/achalasingh/",
  //   imgURL: require("./assets/images/achala.JPG"),
  // },
  {
    id: 9,
    name: "Achint",
    url: "https://linkedin.com/in/a3h1nt/",
    imgURL: require("./assets/images/Achint.jpeg"),
  },
  {
    id: 10,
    name: "Aditya Srivastava",
    url: "https://linkedin.com/in/adityasrivastava0703/",
    imgURL: require("./assets/images/Aditya-Srivastava.jpg"),
  },
  {
    id: 11,
    name: "Aiswarya Priyadarshini",
    url: "https://linkedin.com/in/aiswarya-priyadarshini-9b077813b/",
    imgURL: require("./assets/images/AP.jpeg"),
  },
  {
    id: 12,
    name: "Akshat Kukreja",
    url: "https://linkedin.com/in/akshat987/",
    imgURL: require("./assets/images/AK.jpg"),
  },
  {
    id: 13,
    name: "Amber Mithal",
    url: "https://linkedin.com/in/amber-mithal/",
    imgURL: require("./assets/images/Amber-Mithal.jpg"),
  },
  {
    id: 15,
    name: "Harshit Bhardwaj",
    url: "https://linkedin.com/in/harshit-bhardwaj-65388622a/",
    imgURL: require("./assets/images/Harshit-Bhardwaj.png"),
  },
  {
    id: 16,
    name: "Kayon Karmokar",
    url: "https://linkedin.com/in/kayonkarmokar/",
    imgURL: require("./assets/images/Kayon-Karmokar.JPG"),
  },
  {
    id: 18,
    name: "Mohammad Natiq Khan",
    url: "https://linkedin.com/in/mohammad-natiq-khan/",
    imgURL: require("./assets/images/MNK.jpg"),
  },
  {
    id: 19,
    name: "Naman Gandhi",
    url: "https://linkedin.com/in/hellonamangandhi/",
    imgURL: require("./assets/images/Naman-Gandhi.jpeg"),
  },
  {
    id: 20,
    name: "Ravi Verma",
    url: "https://linkedin.com/in/raviv935/",
    imgURL: require("./assets/images/Ravi-Verma.png"),
  },
  {
    id: 21,
    name: "Rohith Rajan",
    url: "https://linkedin.com/in/rohith-rajan-dc9111/",
    imgURL: require("./assets/images/RR.jpg"),
  },
  {
    id: 22,
    name: "Sakshi Kumari",
    url: "https://linkedin.com/in/sakshikthakur/",
    imgURL: require("./assets/images/Sakshi-Kumari.jpg"),
  },
  {
    id: 23,
    name: "Sanchay Singh",
    url: "https://linkedin.com/in/sanchayofficial/",
    imgURL: require("./assets/images/Sanchay-Singh.png"),
  }, 
   {
    id: 24,
    name: "Sarthak Gupta",
    url: "https://linkedin.com/in/sarthak-gupta-webdev/",
    imgURL: require("./assets/images/sarthak-gupta.jpg"),
  }, 
   {
    id: 25,
    name: "Saumya Agarwal",
    url: "https://linkedin.com/in/saumya-agarwal-591ab3243/",
    imgURL: require("./assets/images/Saumya-Agarwal.jpg"),
  }, 
   {
    id: 27,
    name: "Shreya Punani",
    url: "https://linkedin.com/in/shreya-punani/",
    imgURL: require("./assets/images/SHREYA-PUNANI.heic"),
  }, 
];

const sponsor = [
  {
    id: 0,
    name: "CloudSEK - Gold Sponsor",
    url: "https://www.cloudsek.com/",
    imgURL: require("./assets/sponsor_logo/cloudsek_logo.png"),
  },
  {
    id: 1,
    name: "TELUS Digital - Silver Sponsor",
    url: "https://www.telusdigital.com/",
    imgURL: require("./assets/sponsor_logo/telus_logo.png"),
  },
  {
    id: 2,
    name: "NUVOLLO - Silver Sponsor",
    url: "https://nuvollo.com/",
    imgURL: require("./assets/sponsor_logo/nuvollo_sponsor_image.jpg"),
  },
  {
    id: 3,
    name: "Hack The Box - CTF Sponsor",
    url: "hhttps://www.hackthebox.com/",
    imgURL: require("./assets/sponsor_logo/Hack_the_box_logo.jpeg"),
  },
  {
    id: 4,
    name: "OffSec - CTF Sponsor",
    url: "https://www.offsec.com/",
    imgURL: require("./assets/sponsor_logo/offsec_logo.jpeg"),
  },
]
export { links, updates, team, sponsor };
