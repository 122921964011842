import { GrDocumentText } from "react-icons/gr";

import { links, team, sponsor } from "./data";
import TeamCard from "./TeamCard";
import SponsorCard from "./SponsorCard";

const faqs = [
  {
    id: "0",
    q: "What is DC9111?",
    a: "DEF CON Delhi Group is code named as DC9111 which is one of the biggest DEF CON Groups running in India. ",
  },
  {
    id: "1",
    q: "Where did the name come from?",
    a: " The name DC9111 comes from a variety of things which is DC i.e. short form of DEF CON, “91” which is the telephone code of India and “11” which are the first two digits of ZIP codes all over Delhi, IN. ",
  },
  {
    id: "2",
    q: "What's the price of the events?",
    a: "We are currency flexible so you can pay 0.00 in any currency! Events are totally FREE! ",
  },
  {
    id: "3",
    q: "When and where DEF CON Delhi Group (DC9111) meet? ",
    a: "We usually meet every year at some or the other conference halls, MNC offices and meetup places and if not possible, virtually. All the locations are updated on the social media handles of DC9111.",
  },
  {
    id: "4",
    q: "How does DEF CON Delhi Group (DC9111) manage the expenses? ",
    a: "The event costs are totally managed by the team lead of DC9111. ",
  },
  {
    id: "5",
    q: "What are the rules of DEF CON Delhi Group (DC9111)? ",
    a: "Physical violence is prohibited. Harassment of any kind is prohibited. We don't support use of drug or alcohol in the event area. DC9111 Goons will be available to answer your questions and keep everything safe and sound. Premise's secrity is there to take care of their property. Each has a different way of treatment, and it is advised NOT TO piss off the security personnels. Please note that you DO NOT brag about any of the illegal activities you have been into while exploring your infosec career as there are nummber of attendees from the Indian Government present in the premises. ",
  },
  {
    id: "6",
    q: "I'm not a hacker, should I go to DEF CON Delhi Group (DC9111) events?",
    a: "Absolutely! You should make sure that you have the “I'm here to learn no matter what” attitude. We are pretty sure you will enjoy everything about our events. ",
  },
  {
    id: "7",
    q: "What are Goons?",
    a: "Don't worry, they aren't as scary as debt collectors. They are the members of the DEF CON Delhi Group (DC9111) team. They will be available everywhere during the event so that they can accompany you with anything you need.",
  },
  {
    id: "8",
    q: "How to become a DC9111 goon?",
    a: "Whenever we are recrutiting any of the goons, we will update everywhere on our social media handles. Once selected on certain degree of trust and commitment, you will be tested as volunteers, if we are comfortable with your attitude and your input, you're very much welcome into the team as a Goon!",
  },
  {
    id: "9",
    q: "I am from a non-technical background but interested in security, what should I do?",
    a: "We are open to all. All you need is interest and determination. This domain will not ask you to be technical person from scratch.",
  },
];

const w = [
  {
    id: "w1",
    title: "about.doc",
    iconName: "About Us",
    icon: <GrDocumentText className="icon" />,
    internalStructure: (
      <>
        <h1 className="window-title">About Us</h1>
        <p className="about-text">
          DEF CON Delhi Group a.k.a DC9111 is the local chapter of DEF CON
          Conference held every year in Las Vegas. We are based in the country
          capital New Delhi. We highly endorse programmers, hackers, techies and
          all sort of tech related people and encourage them to come up and
          showcase what they've got! So, let's meet soon!
        </p>
      </>
    ),
  },
  {
    id: "w2",
    title: "contact.doc",
    iconName: "Contact Us",
    icon: <GrDocumentText className="icon" />,
    internalStructure: (
      <>
        <h1 className="window-title">Contact Us</h1>
        <div className="links">
          {links.map((link) => {
            return (
              <a
                href={link.URL}
                key={link.Name}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                {link.icon} {link.Name}
              </a>
            );
          })}
        </div>
      </>
    ),
  },
  // {
  //   id: "w3",
  //   title: "team.doc",
  //   iconName: "Team",
  //   icon: <GrDocumentText className="icon" />,
  //   internalStructure: (
  //     <>
  //       <h1>Team</h1>
  //     </>
  //   ),
  // },
  {
    id: "w4",
    title: "code of conduct.doc",
    iconName: "Code of Conduct",
    icon: <GrDocumentText className="icon" />,
    internalStructure: (
      <>
        <h1 className="window-title">Code of Conduct</h1>
        <p className="section-text">
          At the event, you are most welcome to participate in every sort of
          activity stated that it doesn't cause any ruckus. <br />
          <br /> Your radical viewpoint and questioning attitude will be highly
          appreciated. <br />
          <br />
          We <strong>DO NOT</strong> condone harassment against{" "}
          <strong>ANY</strong> participant, for <strong>ANY</strong> reason.
          <br />
          <br />
          Harassment includes deliberate intimidation and targeting individuals
          in a manner that makes them feel uncomfortable, unwelcomed, or afraid.
          <br />
          <br />
          Participants asked to stop any harassing behavior are expected to
          comply immediately. <br />
          <br /> We (The Team) and the venue security will respond to harassment
          in the manner we deem appropriate, including but not limited to
          expulsion from the event with the surety that you don't show up at any
          following events we conduct. <br />
          <br /> This Code of Conduct applies to everyone participating at DEF
          CON Delhi Group (DC9111) events - from attendees and exhibitors to
          speakers, press {"&"} volunteers (Goons). <br />
          <br /> Anyone can report harassment. If you are being harassed, notice
          that someone else is being harassed, or have any other concerns, you
          can contact a Goon, go to the registration desk, or info booth. <br />
          <br />
        </p>
        <hr /> <br />
        <p className="title f">
          TX <br /> Team Lead <br /> DEF CON Delhi Group (DC9111)
        </p>
      </>
    ),
  },
  {
    id: "w5",
    title: "faq.doc",
    iconName: "FAQ",
    icon: <GrDocumentText className="icon" />,
    internalStructure: (
      <>
        <h1 className="window-title">FAQ</h1>
        <p className="section-text">
          A list of Frequently Asked Questions:
          <ul className="faq-list">
            {faqs.map((f) => {
              return (
                <li className="faq-container" key={f.id}>
                  <p className="faq-question">{f.q}</p>
                  <p className="faq-answer">{f.a}</p>
                </li>
              );
            })}
          </ul>
        </p>
      </>
    ),
  },
  {
    id: "w6",
    title: "protocol.doc",
    iconName: "Protocol.doc",
    icon: <GrDocumentText className="icon" />,
    internalStructure: (
      <>
        <h1 className="window-title">Protocol</h1>
        <p className="section-text">
          At the event, you are most welcome to participate in every sort of
          activity stated that it doesn't cause any ruckus. <br />
          <br /> Your radical viewpoint and questioning attitude will be highly
          appreciated. <br />
          <br />
          We <strong>DO NOT</strong> condone harassment against{" "}
          <strong>ANY</strong> participant, for <strong>ANY</strong> reason.
          <br />
          <br />
          Harassment includes deliberate intimidation and targeting individuals
          in a manner that makes them feel uncomfortable, unwelcomed, or afraid.
          <br />
          <br />
          Participants asked to stop any harassing behavior are expected to
          comply immediately. <br />
          <br /> We (The Team) and the venue security will respond to harassment
          in the manner we deem appropriate, including but not limited to
          expulsion from the event with the surety that you don't show up at any
          following events we conduct. <br />
          <br /> This Code of Conduct applies to everyone participating at DEF
          CON Delhi Group (DC9111) events - from attendees and exhibitors to
          speakers, press {"&"} volunteers (Goons). <br />
          <br /> Anyone can report harassment. If you are being harassed, notice
          that someone else is being harassed, or have any other concerns, you
          can contact a Goon, go to the registration desk, or info booth. <br />
          <br />
        </p>
        <hr /> <br />
        <p className="title f">
          TX <br /> Team Lead <br /> DEF CON Delhi Group (DC9111)
        </p>
        <p>Excerpts from DEF CON Conference Code of Conduct</p>
      </>
    ),
  },
  {
    id: "w7",
    title: "Team.doc",
    iconName: "Team.doc",
    icon: <GrDocumentText className="icon" />,
    internalStructure: (
      <>
        <h1 className="window-title">Team</h1>
        <div className="team-grid-container">
          {team.map((t) => {
            return <TeamCard details={t} key={t.id} />;
          })}
        </div>
      </>
    ),
  },
  {
    id: "w8",
    title: "Sponsor.doc",
    iconName: "Sponsor.doc",
    icon: <GrDocumentText className="icon" />,
    internalStructure: (
      <>
        <h1 className="window-title">Sponsor</h1>
        <div className="team-grid-container">
          {sponsor.map((s) => {
            return <SponsorCard details={s} key={s.id} />;
          })}
        </div>
      </>
    ),
  },
];

export default w;
