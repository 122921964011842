import React from "react";

import { FaLinkedin } from "react-icons/fa6";

const TeamCard = ({ details }) => {
  const { id, name, url, imgURL } = { ...details };
  return (
    <div className="card volunteer">
      <img src={imgURL} alt={id} className="avatar" />
      <div className="card-text">
        <p className="name">{name}</p>
        <a className="linkedin" href={url} target="_blank" rel="noreferrer">
          <FaLinkedin />
        </a>
      </div>
    </div>
  );
};

export default TeamCard;
